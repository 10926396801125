<template>
  <div>
    <Sale
      textColor="#fff"
      primaryColor="#000"
      accentColor="#7171716B"
      label="Black Friday Deals"
      :image="require('@/assets/img/black-friday.png')"
      promotion="black_friday"
    />

    <section
      style="margin-top: 30px"
      v-if="isLoadingStoreData || pageData?.top_deals"
    >
      <ForYou
        :deals="pageData.top_deals"
        :refetch="refetchStoreData"
        :isLoading="isLoadingStoreData"
      />
      <Banner />
    </section>

    <section
      style="margin-top: 30px"
      v-if="isLoadingStoreData || pageData?.best_selling_products"
    >
      <BestSelling
        :deals="pageData.best_selling_products"
        :refetch="refetchStoreData"
        :isLoading="isLoadingStoreData"
      />
      <Banner />
    </section>

    <Sale
      textColor="#000"
      primaryColor="#FBBC05"
      accentColor="#FBBC0538"
      label="Flash Sales"
      promotion="flash_sales"
      :image="require('@/assets/img/flash-sale.png')"
    />

    <Sale
      textColor="#000"
      primaryColor="#92E3A9"
      accentColor="#12FF462B"
      label="Payday Sales"
      promotion="Payday Sales"
    />

    <section
      style="margin-top: 30px"
      v-if="isLoadingStoreData || pageData?.top_deals"
    >
      <TopDeals
        :deals="pageData.top_deals"
        :refetch="refetchStoreData"
        :isLoading="isLoadingStoreData"
      />
      <Banner />
    </section>

    <section
      style="margin-top: 30px"
      v-if="isLoadingStoreData || pageData?.popular_vendors"
    >
      <PopularVendors
        :deals="pageData.popular_vendors"
        :refetch="refetchStoreData"
        :isLoading="isLoadingStoreData"
      />
      <Banner />
    </section>

    <section style="margin-top: 30px">
      <RecentlyViewed />
    </section>
  </div>
</template>

<script setup>
import axios from "axios";
import Sale from "./Sale.vue";
import ForYou from "./ForYou.vue";
import TopDeals from "./TopDeals.vue";
import BestSelling from "./BestSelling.vue";
import Banner from "@/components/static/Banner.vue";
import useCampaigns from "@/composables/useCampaigns";
import BlockLoader from "@/components/static/BlockLoader.vue";

import { computed, defineAsyncComponent } from "vue";
import { insertRandomly } from "@/lib/utils";
import { useQuery } from "@tanstack/vue-query";
import { fail } from "@/lib/toast";

const PopularVendors = defineAsyncComponent({
  loader: () => import("./PopularVendors.vue"),
  loadingComponent: BlockLoader,
});

const RecentlyViewed = defineAsyncComponent({
  loader: () => import("./RecentlyViewed.vue"),
  loadingComponent: BlockLoader,
});

const { data: campaignData } = useCampaigns(0);

const {
  data: storeData,
  refetch: refetchStoreData,
  isLoading: isLoadingStoreData,
} = useQuery({
  queryKey: ["dealstop"],
  queryFn: getStoreData,
  retry: 1,
  keepPreviousData: true,
});

const pageData = computed(() => {
  const productDataSnapshot = { ...storeData.value };
  const campaigns = campaignData.value;

  if (campaigns && productDataSnapshot?.best_selling_products?.length) {
    productDataSnapshot.best_selling_products = insertRandomly(
      productDataSnapshot.best_selling_products,
      campaigns
    );
  }

  if (campaigns && productDataSnapshot?.top_deals?.length) {
    productDataSnapshot.top_deals = insertRandomly(
      productDataSnapshot.top_deals,
      campaigns
    );
  }

  return productDataSnapshot;
});

async function getStoreData() {
  try {
    const response = await axios.get("popular-stores-and-vendors");
    const data = await response.data.data;

    return data;
  } catch (err) {
    fail("An error occurred while fetching products!");
  }
}
</script>
