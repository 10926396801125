import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import CartModule from "@/modules/cart/store";
import api from "@/api";
import CheckoutModule from "@/modules/checkout/store";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  key: "emart_store_vuex",
  storage: window.localStorage,
  reducer: (state) => {
    const updatedState = { ...state };

    delete updatedState.cartModule;
    delete updatedState.editDelivery;
    delete updatedState.menuState;
    delete updatedState.AccessState;

    return updatedState;
  },
});

export default new Vuex.Store({
  state: {
    menuState: false,
    logout: false,
    ChatActive: true,
    AccessState: null,
    showCover: true,
    BankWithdraw: false,
    BankDeposit: false,
    sizeGuide: null,
    editDelivery: false,
    insight: [],
    checkout: {
      group_reference_id: "",
      order_amount: 0,
      delivery_address: "",
      delivery_date: null,
      delivery_fee: 0,
      payment_mode: "",
      delivery_method: "door_delivery",
      delivery_company: "",
      delivery_name: "",
      use_smart_coin_as_discount: false,
      town_id: "",
      station_code: "",
      recaptcha: null,
      callback_url: "",
      payment_gateway: "paystack",
      courier_id: null,
    },
    saveOn: false,
    saveOff: false,
    tracking: false,
    brands: [],
    categories: [],
    delivery: "",
    availability: false,
    price: null,
    jwt_token: null,
    jwt_passphrase: null,
    trackedProductsMap: {}, // { [type]: { [key]: boolean } }
    productsInsightsBatch: {}, // { [type]: [key] }
    isSavingProductBatch: false,
    trackedCampaignsMap: {}, // { [type]: { [key]: boolean } }
    campaignsInsightsBatch: {}, // { [type]: [key] }
    isSavingCampaignBatch: false,
    userLocation: null,
    savedItems: {},
    sort_by: "",
    fuel_type: "",
    mileage: "",
    carPrice: "",
    search: "",
    model: "",
    user: null,
  },
  actions: {
    handleMenu({ commit }) {
      let value = !this.state.menuState;
      commit("setMenu", value);
    },

    async Subscribe(Info) {
      return await api.post("newsletter/subscribe", Info);
    },

    async SaveProduct(Id) {
      return await api.post(`/products/save/${Id}`);
    },
  },
  mutations: {
    setMenu(state, value) {
      state.menuState = value;
    },
    setIsSavingProductBatch(state, value) {
      state.isSavingProductBatch = value;
    },
    setProductsInsightsBatch(state, { batch, type }) {
      Vue.set(state.productsInsightsBatch, type, batch);

      if (!batch.length) {
        Vue.delete(state.productsInsightsBatch, type);
      }
    },
    addToProductInsightsBatch(state, { productId, type }) {
      const selectedMap = state.trackedProductsMap[type] || {};
      const selectedBatch = state.productsInsightsBatch[type] || [];

      if (productId && !selectedMap[`${productId}`]) {
        Vue.set(selectedMap, productId, true);
        Vue.set(state.trackedProductsMap, type, selectedMap);

        selectedBatch.push(productId);
        Vue.set(state.productsInsightsBatch, type, selectedBatch);
      }
    },
    setIsSavingCampaignBatch(state, value) {
      state.isSavingCampaignBatch = value;
    },
    setCampaignsInsightsBatch(state, { batch, type }) {
      Vue.set(state.productsCampaignsBatch, type, batch);

      if (!batch.length) {
        Vue.delete(state.productsCamapignsBatch, type);
      }
    },
    addToCampaignInsightsBatch(state, { campaignId, type }) {
      const selectedMap = state.trackedCampaignsMap[type] || {};
      const selectedBatch = state.campaignsInsightsBatch[type] || [];

      if (campaignId && !selectedMap[`${campaignId}`]) {
        Vue.set(selectedMap, campaignId, true);
        Vue.set(state.trackedCampaignsMap, type, selectedMap);

        selectedBatch.push(campaignId);
        Vue.set(state.campaignsInsightsBatch, type, selectedBatch);
      }
    },
    setUserLocation(state, data) {
      state.userLocation = data;
    },
    setSavedItems(state, value = []) {
      // TODO: Refactor for pagination and extreme cases
      const updatedItems = {};

      value.forEach((item) => {
        if (!item?.product?.uuid) return;

        updatedItems[item.product.uuid] = item;
      });

      state.savedItems = updatedItems;
    },

    // TODO: Irregular Data map, refactor to centralised user state
    setSaveItem(state, id, value) {
      Vue.set(state.savedItems, id, value);
    },
    setUser(state, user) {
      state.user = user;
    },
  },
  plugins: [vuexLocal.plugin],
  modules: {
    cartModule: CartModule,
    checkoutModule: CheckoutModule,
  },
});
